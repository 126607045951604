var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"flat":"","items":_vm.rotations,"items-per-page":10},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('h1',{staticClass:"mb-0 text-xl-h6",on:{"click":function($event){return _vm.$router.push('customers/'+item.contract.customer.id)}}},[_vm._v(" "+_vm._s(item.contract.customer.full_name))]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(item.contract.customer.email))])])]}},{key:"item.caretaker",fn:function(ref){
var item = ref.item;
return [(!item.assignee)?_c('v-chip',{staticClass:"pointer",attrs:{"small":""}},[_vm._v("Unassigned")]):_c('div',{staticClass:"user-details",on:{"click":function($event){return _vm.$router.push('users/'+item.assignee.id+'/profile')}}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(item.assignee.name))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.assignee.email))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pointer",attrs:{"label":"","color":_vm.getRotationStatusColor(item)}},[_vm._v(_vm._s(item.status))]),(item.status === 'gap')?_c('v-icon',{attrs:{"color":"#F44336"}},[_vm._v("mdi-alert-circle-outline")]):_vm._e(),(item.has_problem)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"#f49636"}},[_vm._v("mdi-alert-circle-outline")]):_vm._e()]}},{key:"item.confirmed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pointer",attrs:{"label":"","color":_vm.getConfirmationColor(item.recruiter_confirmed)}},[_vm._v(" "+_vm._s(_vm.getConfirmationText(item.recruiter_confirmed))+" ")])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.start_date,"DD MMM YY")))])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.end_date,"DD MMM YY")))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticClass:"pointer"},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.last_name))])]}},{key:"item.case_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"white","color":_vm.getCaseColor(item)},on:{"click":function($event){return _vm.$router.push('contracts/'+item.contract_id)}}},[_vm._v(" № "+_vm._s(item.contract_job_id)+"-"+_vm._s(item.rotation_number)+" ")])]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",on:{"click":function($event){return _vm.confirmRotation(item)}}},[_vm._v("Confirm")]),_c('v-btn',{on:{"click":function($event){return _vm.denyRotation(item)}}},[_vm._v("Deny")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }